import { PAGE } from '~/constants/router'
import { IMenuItemMb } from '~/types/home'

export function menuBottom(isHome: boolean): IMenuItemMb[] {
  return [
    {
      name: isHome ? 'Khuyến Mãi' : 'Trang Chủ',
      icon: `/assets/images/components/common/menu-bottom/icon-${isHome ? 'promotion' : 'home'}.svg`,
      link: isHome ? PAGE.PROMOTION : PAGE.HOME,
      loginRequired: false
    },
    {
      name: 'Lịch Sử',
      icon: '/assets/images/components/common/menu-bottom/icon-history.svg',
      iconActive: '/assets/images/components/common/menu-bottom/icon-history-active.svg',
      link: `${PAGE.ACCOUNT_HISTORY_TRANSACTION}`,
      loginRequired: true
    },
    {
      name: 'Nạp Rút',
      icon: '/assets/images/components/common/menu-bottom/icon-deposit.svg',
      iconActive: '/assets/images/components/common/menu-bottom/icon-deposit-active.svg',
      link: '/account/deposit/codepay',
      class: 'deposit',
      loginRequired: true
    },
    {
      name: 'Hỗ Trợ',
      icon: '/assets/images/components/common/menu-bottom/icon-support.svg',
      iconActive: '/assets/images/components/common/menu-bottom/icon-support-active.svg',
      link: PAGE.SUPPORT,
      loginRequired: false
    },
    {
      name: 'Tài Khoản',
      icon: '/assets/images/components/common/menu-bottom/icon-account.svg',
      iconActive: '/assets/images/components/common/menu-bottom/icon-account-active.svg',
      link: PAGE.ACCOUNT,
      class: 'account',
      loginRequired: true
    }
  ]
}
