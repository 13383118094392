<template>
  <div id="tooltip-menu-bottom" class="wrapper-menu-bottom">
    <client-only>
      <div class="wrapper-menu-bottom__content">
        <MenuItem v-for="(item, index) in menus" :key="item.icon" :item="item" :class="`menu-item--${index}`" />
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import MenuItem from './menu-item.vue'
import { PAGE } from '~/constants/router'
import { menuBottom } from '~/resources/menu-bottom'
const route = useRoute()
const menus = computed(() => menuBottom(route.path === PAGE.HOME))
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/layouts/footer/index.scss"></style>
