<template>
  <div>
    <MobileHeader v-show="!isHideHeaderAndFooter && !isHideHeader" />
    <slot />
    <MenuBottom v-if="!isHideHeaderAndFooter" />
    <client-only>
      <PageAccount v-if="isLogged" />
      <PageDeposit v-if="isLogged" />
      <PageWithdraw v-if="isLogged" />
      <ModalLogin v-if="!isLogged" />
      <ModalResetPassword v-if="!isLogged" />
      <ModalBlockGame v-if="isLogged" />
      <ModalChangeDisplayName v-if="isLogged" />
    </client-only>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
// import MenuBottom from '~/components/mobile/common/menu-bottom/index.vue'
import ModalLogin from '~/components/common/modals/login/index.vue'
import ModalResetPassword from '~/components/common/modals/reset-password/index.vue'
import MobileHeader from '~/components/mobile/layouts/header/index.vue'
import MenuBottom from '~/components/mobile/layouts/footer/index.vue'
import PageAccount from '~/components/mobile/common/page-account/index.vue'
import PageDeposit from '~/components/mobile/common/page-deposit/index.vue'
import PageWithdraw from '~/components/mobile/common/page-withdraw/index.vue'
import { useAppStore } from '~/store/app'
import { useRefresh } from '~/composables/user/useRefresh'
import { useInitData } from '~/composables/user/useInitData'
import { PAGE_HIDE_HEADER_FOOTER_MB, PAGE_HIDE_HEADER_MB } from '~/constants/common'
const ModalBlockGame = defineAsyncComponent(() => import('~/components/desktop/modals/block-game/index.vue'))
const ModalChangeDisplayName = defineAsyncComponent(
  () => import('~/components/desktop/modals/change-display-name/index.vue')
)
const { $pinia } = useNuxtApp()
const route = useRoute()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const { refreshTokenInterval, refreshToken } = useRefresh()
const { fetchInitData, fetchPlan } = useInitData()
onMounted(() => {
  refreshToken(true)
  if (isLogged.value) {
    userInitData()
    removeParams()
  }

  setTimeout(() => {
    const modalIds = ['modalBlockGame', 'modalCancelPromotion', 'modalDisplayName']

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const modalElement = entry.target as HTMLElement
        const modalId = modalElement.id
        if (entry.isIntersecting) {
          const bodyElement = document.querySelector('body')
          if (bodyElement) {
            disablePageScroll(bodyElement)
          }
        } else {
          enablePageScroll(document.querySelector('body'))
        }
      })
    }
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0 })

    modalIds.forEach((modalId) => {
      const modalElement = document.getElementById(modalId)
      if (modalElement) {
        observer.observe(modalElement)
      }
    })
  }, 1500)
})
watch(isLogged, () => {
  refreshToken(true)
  if (isLogged.value) {
    userInitData()
    removeParams()
  }
})

const userInitData = () => {
  refreshTokenInterval()
  fetchInitData()
  fetchPlan()
}

const isHideHeaderAndFooter = computed(() => {
  return (
    PAGE_HIDE_HEADER_FOOTER_MB.includes(route?.name as string) || PAGE_HIDE_HEADER_FOOTER_MB.includes(route.fullPath)
  )
})

const isHideHeader = computed(() => {
  return PAGE_HIDE_HEADER_MB.includes(route?.name as string)
})

const removeParams = () => {
  const query = { ...route.query }
  delete query.popup
  navigateTo({
    query: { ...query }
  })
}
</script>
<style src="assets/scss/layout/mobile.scss" scoped lang="scss" />
